var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-date-picker',{attrs:{"min-date":_vm.data.minDate,"max-date":_vm.data.maxDate,"color":_vm.data.dateColor,"value":_vm.value,"mode":_vm.dateMode,"is24hr":_vm.timeFormat==24,"isDark":_vm.$store.state.theme.darkMode,"masks":{
                   input:[_vm.dateFormat],
                   inputDateTime:[_vm.dateFormat+' h:mm A'],
                   inputDateTime24hr:[_vm.dateFormat+' HH:mm'],
                   inputTime:['h:mm A'],
                   inputTime24hr:['HH:mm']
               },"isRange":_vm.isRange,"popover":{visibility: 'click', placement: _vm.data.popoverPosition}},on:{"input":_vm.input},scopedSlots:_vm._u([{key:"default",fn:function(ref){
               var inputValue = ref.inputValue;
               var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"date-picker-input",class:{'date-picker-input-group':_vm.borderGroup}},[_c('div',{staticClass:"input-group",class:{'disabled':_vm.data.disabled},on:{"focusin":function($event){_vm.borderGroup=true},"focusout":function($event){_vm.borderGroup=false}}},[(_vm.isRange)?[_c('input',_vm._g({staticClass:"form-control",attrs:{"placeholder":_vm.data.placeholder,"readonly":""},domProps:{"value":inputValue.start || inputValue.end ? inputValue.start+' - '+inputValue.end: ''}},inputEvents.start || inputEvents.end))]:[_c('input',_vm._g({staticClass:"form-control",attrs:{"placeholder":_vm.data.placeholder},domProps:{"value":inputValue ? inputValue : ''}},inputEvents))],_vm._v(" "),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_c('app-icon',{attrs:{"name":'calendar'}})],1)])],2)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }